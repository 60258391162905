import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { DateTimePickerComponent } from "@unity/components";

export default function DynamicProduct({ error, ...props }) {
  const [loading, setLoading] = useState({ status: true });
  const [minimumTerm, setMinimumTerm] = useState(null);
  const [valid, setValid] = useState({ from: null, to: null });
  const [data, setData] = useState(false);

  const uneditable = false;

  function handleChange({ name, value }) {
    let localData = JSON.parse(JSON.stringify(data));
    localData[name] = value;
    setData(localData);
  }

  const handleSubmit = async () => {
    let products = props.data;
    let product = products[props.productIndex];
    let line = product.lines[props.lineIndex];
    product.valid_from = valid.from || null;
    product.valid_to = valid.to || null;
    line.minimum_term = minimumTerm;
    line.options[props.optionIndex] = data;
    console.log("updated product: ", product);
    props.updateProduct(product.id, product);
    props.handleChange(products);
    props.handleClose();
  };

  const getData = async () => {
    try {
      const products = props.data;
      const product = products[props.productIndex];
      const line = product.lines[props.lineIndex];
      const option =
        line && line.options ? line.options[props.optionIndex] : null;
      console.log(option);
      if (option) setData(option);
      if (line) setMinimumTerm(line.minimum_term);
      if (product) setValid({ from: product.valid_from, to: product.valid_to });
    } catch (err) {
      console.log(err);
    }
  };

  const loadInitData = async () => {
    setLoading({
      status: true,
      data: "Loading Contract Form, Please Wait....",
    });
    //if(props.type == "edit" && props.contract){ setContract(props.contract) };
    await getData();
    setLoading({ status: false, data: "" });
  };

  useEffect(() => {
    loadInitData();
  }, [props.open]);

  if (!props.open) {
    return null;
  }

  const handleClose = () => {
    setData(false);
    props.setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>{props.label}</DialogTitle>

      <DialogContent>
        {loading.status ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <br />
            <span>{loading.data}</span>
          </div>
        ) : (
          <>
            <h2>General</h2>
            <div className="form-row">
              <div className="form-group col-lg-12">
                <TextField
                  label="Material Name"
                  name="material_name"
                  type="text"
                  value={data.material_name || ""}
                  error={(error && error.name) || false}
                  helperText={(error && error.name) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>
              <div className="form-group col-lg-12">
                <TextField
                  label="Supplier Name"
                  name="supplier_name"
                  type="text"
                  value={data.supplier_name || ""}
                  error={(error && error.name) || false}
                  helperText={(error && error.name) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>
              <div className="form-group col-lg-12">
                <TextField
                  label="Material Ref"
                  name="material_ref"
                  type="text"
                  value={data.material_ref || ""}
                  size="small"
                  fullWidth
                  disabled
                ></TextField>
              </div>
              <div className="form-group col-lg-12">
                <TextField
                  label="Minimum Term"
                  name="minimum_term"
                  type="number"
                  value={minimumTerm || ""}
                  onChange={(e) => uneditable || setMinimumTerm(e.target.value)}
                  size="small"
                  fullWidth
                ></TextField>
              </div>
              <div className="form-group col-lg-6">
                <DateTimePickerComponent
                  value={valid.from || null}
                  label="Valid From"
                  name="valid_from"
                  onChange={(n, v) =>
                    setValid((prev) => ({ ...prev, from: v.$d.toJSON() }))
                  }
                />
              </div>
              <div className="form-group col-lg-6">
                <DateTimePickerComponent
                  label="Expiry Date"
                  value={valid.to || null}
                  name="valid_to"
                  onChange={(n, v) =>
                    setValid((prev) => ({ ...prev, to: v.$d.toJSON() }))
                  }
                />
              </div>
            </div>

            <h2>Pricing</h2>
            <div className="form-row">
              <div className="form-group col-lg-12">
                <TextField
                  label="Material Cost"
                  name="material_cost"
                  type="number"
                  value={data.material_cost || ""}
                  error={(error && error.name) || false}
                  helperText={(error && error.name) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-lg-12">
                <FormGroup>
                  <FormControlLabel
                    labelPlacement="bottom"
                    control={
                      <Switch
                        checked={data.in_basket}
                        onChange={() =>
                          props.handleOptionSelect(
                            props.productIndex,
                            props.lineIndex,
                            props.optionIndex
                          )
                        }
                      />
                    }
                    label={
                      data.in_basket
                        ? "This option has been selected as part of the dynamic product."
                        : "Would you to select this product option for the customer? Please note, this will deselect any other option related to this dynamic product."
                    }
                  />
                </FormGroup>
              </div>
            </div>

            <h2>Summary</h2>
            <div className="form-row">
              <div className="form-group col-lg-4">
                <TextField
                  label="Total Price"
                  type="text"
                  value={
                    data.material_cost *
                      props.data[props.productIndex].lines[props.lineIndex]
                        .quantity || ""
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                ></TextField>
              </div>
              <div className="form-group col-lg-4">
                <TextField
                  label="Periodic Code"
                  name="account_no"
                  type="text"
                  value={data.periodic_code_id || ""}
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                ></TextField>
              </div>
              <div className="form-group col-lg-4">
                <TextField
                  label="Charge Code"
                  name="account_no"
                  type="text"
                  value={data.charge_code_id || ""}
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                ></TextField>
              </div>
            </div>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: "#2100b7" }}
          onClick={() => handleSubmit()}
        >
          SAVE
        </Button>
        <Button
          variant="outlined"
          size="large"
          style={{ border: "1px solid #2100b7", color: "#2100b7" }}
          onClick={() => handleClose()}
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}
