import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  MenuItem,
  Menu,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";

import DynamicDocuments from "./DynamicDocumentModal";
import ResourceModal from "./ResourceModal";
import DynamicProduct from "./DynamicProductModal";
import StaticProduct from "./StaticProductModal";
import {
  ProductsApi,
  ContractsApi,
  DealBuilderApi,
  DateTimePickerComponent,
} from "@unity/components";
import LoadingBackdrop from "../common/loadingBackdrop";
import { useTour } from "../services/TourContext";

function Options({ error, ...props }) {
  //This holds the static and dynamic rows as well as the options for a dynamic.
  const { row } = props;
  const product = props.row;
  const tourOpen = useTour();

  const [open, setOpen] = useState(false);
  const [staticOpen, setStaticOpen] = useState(false);
  const [dynamicOpen, setDynamicOpen] = useState(false);
  const [documentOpen, setDocumentOpen] = useState(false);

  const optionIndex = useRef(0); //defaut value is zero for now until I add the edit icons to the options

  const handleStaticDialog = () => {
    staticOpen ? setStaticOpen(false) : setStaticOpen(true);
  };

  const handleDynamicDialog = (i) => {
    optionIndex.current = i;
    dynamicOpen ? setDynamicOpen(false) : setDynamicOpen(true);
  };

  const handleDynamicDocumentDialog = (i) => {
    optionIndex.current = i;
    documentOpen ? setDocumentOpen(false) : setDocumentOpen(true);
  };

  const handleExpandDynamic = () => {
    setOpen(!open);
    if (tourOpen) {
      window.dispatchEvent(
        new CustomEvent("tourStepIndex", { detail: { stepIndex: 1 } })
      );
    }
  };

  const handleOptionSelect = (prodIndex, lineIndex, optionIndex) => {
    let products = props.data;
    let product = products[prodIndex];
    let line = product.lines[lineIndex];
    let option = line.options[optionIndex];
    let optionInBasket = option?.in_basket === true;

    if (checkDynamicStatus(option)) {
      if (optionInBasket) {
        line.material_cost = null;
        option.in_basket = false;
      } else {
        line.options = line.options.map((el) =>
          el.id === option.id
            ? { in_basket: true, ...el }
            : { in_basket: false, ...el }
        );
        line.material_cost = option.material_cost;
      }
      props.updateProduct(product.id, product);
      props.handleChange(products);
    } else {
      alert(
        "Unable to select option. Please ensure the option has a name and price."
      );
    }
  };

  const checkDynamicStatus = (data, type) => {
    if (!type) {
      if (!data.material_cost) {
        return false;
      }
      return true;
    }
    const style = {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 4,
      paddingBottom: 4,
      borderRadius: 30,
    };
    if (!data.material_cost) {
      return (
        <span
          style={{
            ...style,
            backgroundColor: "#FFE4E6",
            color: "#9A3412",
          }}
        >
          Incomplete
        </span>
      );
    } else if (data.in_basket) {
      return (
        <span
          style={{
            ...style,
            backgroundColor: "#bbf7d0",
            color: "#15803d",
          }}
        >
          Selected
        </span>
      );
    } else {
      return (
        <span
          style={{
            ...style,
            backgroundColor: "#bbdefb",
            color: "#1565c0",
          }}
        >
          Ready to select
        </span>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {product.dynamic ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleExpandDynamic}
              className="tour-contract-dynamic-expand"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
        <TableCell component="th" scope="row">
          {product.dynamic ? (
            <span
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 4,
                paddingBottom: 4,
                backgroundColor: "#FFE4E6",
                borderRadius: 30,
                color: "#9A3412",
              }}
            >
              Dynamic
            </span>
          ) : (
            <span
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 4,
                paddingBottom: 4,
                backgroundColor: "#bae6fd",
                borderRadius: 30,
                color: "#0369a1",
              }}
            >
              Static
            </span>
          )}
        </TableCell>
        <TableCell>{product.material_name}</TableCell>
        <TableCell>{product.minimum_term}</TableCell>
        <TableCell>{product.material_cost}</TableCell>
        <TableCell>{product.quantity}</TableCell>
        <TableCell>
          {product.dynamic ? (
            product.material_cost ? (
              product.material_cost * product.quantity
            ) : (
              <span
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 4,
                  paddingBottom: 4,
                  backgroundColor: "#FFE4E6",
                  borderRadius: 30,
                  color: "#9A3412",
                }}
              >
                Select an option
              </span>
            )
          ) : (
            product.material_cost * product.quantity
          )}
        </TableCell>
        <TableCell>{row.protein}</TableCell>
        <TableCell>{product.quantity}</TableCell>
        {!product.dynamic ? (
          <TableCell>
            <IconButton
              aria-label="edit"
              //color="primary"
              onClick={() => handleStaticDialog(props.row, props.lineIndex)}
              style={{ color: props.readOnly ? "#bdbdbd" : "#2100b7" }}
              className="tour-contract-option-edit"
              disabled={props.readOnly}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>
      {/*Below is the collapsable row. The header is about this*/}

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="tour-contract-options-overview" sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Options
              </Typography>
              <Table size="small" aria-label="options">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Supplier Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Unit Price
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Min. Term
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Valid From
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Valid To
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {product.options &&
                    product.options.map((o, index) => (
                      <TableRow key={o.date}>
                        <TableCell component="th" scope="row">
                          {o.material_name}
                        </TableCell>
                        <TableCell>{o.supplier_name}</TableCell>
                        <TableCell>{o.material_cost}</TableCell>
                        <TableCell>{product.minimum_term}</TableCell>
                        <TableCell>
                          <DateTimePickerComponent
                            label="valid from"
                            value={props.data[props.productIndex].valid_from}
                            readOnly
                          />
                        </TableCell>
                        <TableCell>
                          <DateTimePickerComponent
                            label="valid from"
                            value={props.data[props.productIndex].valid_to}
                            readOnly
                          />
                        </TableCell>
                        <TableCell>{checkDynamicStatus(o, "table")}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="edit"
                            //color="primary"
                            onClick={() => handleDynamicDialog(index)}
                            style={{
                              color: props.readOnly ? "#bdbdbd" : "#2100b7",
                            }}
                            className="tour-contract-option-edit"
                            disabled={props.readOnly}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            className="tour-contract-option-docs"
                            onClick={() => handleDynamicDocumentDialog(index)}
                          >
                            <DescriptionIcon style={{ color: "#2100b7" }} />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              handleOptionSelect(
                                props.productIndex,
                                props.lineIndex,
                                index
                              )
                            }
                            style={{
                              color: props.readOnly ? "#bdbdbd" : "#2100b7",
                            }}
                            className="tour-contract-option-add"
                            disabled={props.readOnly}
                          >
                            {o.in_basket ? (
                              <RadioButtonCheckedIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <StaticProduct
        open={staticOpen}
        setOpen={setStaticOpen}
        data={props.data}
        handleChange={props.handleChange}
        productIndex={props.productIndex}
        lineIndex={props.lineIndex}
        updateProduct={props.updateProduct}
        error={error}
      />

      <DynamicProduct
        open={dynamicOpen}
        setOpen={setDynamicOpen}
        handleOptionSelect={handleOptionSelect}
        data={props.data}
        handleChange={props.handleChange}
        productIndex={props.productIndex}
        lineIndex={props.lineIndex}
        optionIndex={optionIndex.current}
        updateProduct={props.updateProduct}
        error={error}
      />

      <DynamicDocuments
        open={documentOpen}
        onClose={() => handleDynamicDocumentDialog(optionIndex.current)}
        data={props.data}
        handleChange={props.handleChange}
        handleProductUpdate={props.updateProduct}
        productIndex={props.productIndex}
        lineIndex={props.lineIndex}
        optionIndex={optionIndex.current}
        updateProduct={props.updateProduct}
        error={error}
        readOnly={props.readOnly}
      />
    </React.Fragment>
  );
}

function Row(props) {
  const { row } = props;
  const tourOpen = useTour();

  const [open, setOpen] = useState(false);
  const [resourceOpen, setResourceOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleResourceDialog = () => {
    resourceOpen ? setResourceOpen(false) : setResourceOpen(true);
  };

  const handleDeleteProduct = async () => {
    props.setLoading(true);
    const res = await DealBuilderApi.deleteLiveProduct(row.id);
    props.handleTriggerChange();
    props.setLoading(false);
  };

  const handleUpdateProduct = async (id, product) => {
    props.setLoading(true);
    const res = await ContractsApi.updateLiveProduct(id, product, "product");
    if (res.success) {
      console.log(res);
    } else if (res.errors) {
      setError(res.errors);
    }
    props.setLoading(false);
  };

  const handleExpandLine = () => {
    setOpen(!open);
    if (tourOpen) {
      window.dispatchEvent(
        new CustomEvent("tourStepIndex", { detail: { stepIndex: 1 } })
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleExpandLine}
            className="tour-contract-line-expand"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.product_ref}
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>
          <IconButton
            className="tour-contract-product-docs"
            onClick={() => handleResourceDialog()}
          >
            <FolderIcon style={{ color: "#2100b7" }} />
          </IconButton>

          <IconButton
            className="tour-contract-product-delete"
            onClick={handleDeleteProduct}
            disabled={props.readOnly}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
        {/* <TableCell align="right">{row.protein}</TableCell> */}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="tour-contract-lines" sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Products
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Type</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Min. Term
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Unit Price
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Qty</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Price</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>PC</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>CC</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.row.lines.map((r, index) => (
                    <>
                      <Options
                        key={r.date}
                        row={r}
                        productIndex={props.productIndex}
                        lineIndex={index}
                        handleChange={props.handleChange}
                        data={props.data}
                        updateProduct={handleUpdateProduct}
                        error={error}
                        readOnly={props.readOnly}
                      />
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ResourceModal
        open={resourceOpen}
        setOpen={setResourceOpen}
        data={props.data}
        handleChange={props.handleChange}
        productIndex={props.productIndex}
        lineIndex={props.lineIndex}
        product_id={props.product_id}
        product_line_id={props.product_line_id}
        product_group_id={props.product_group_id}
        product_cat_id={props.product_cat_id}
        sbu_id={props.sbu_id}
      />
      <Dialog open={Boolean(error)} onClose={() => setError(null)}>
        <DialogContent>
          <DialogContentText>{error}</DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default function ProductTable(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeProducts, setActiveProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      //should i be getting active deals or poducts here?
      const res = await ProductsApi.getActiveProducts({
        read_all: true,
        pagination: false,
      });
      if (res) {
        setActiveProducts(res.data);
      }
    };
    getData();
  }, []);

  const handleSelectProduct = async (product) => {
    setLoading(true);
    const payload = {
      ...product,
      contract_id: props.contractId,
      deal_id: props.deal?.id,
    };
    const res = await ContractsApi.addCreateDeal(payload);
    if (res?.success) {
      setAnchorEl(null);
      props.handleTriggerChange();
    }
    setLoading(false);
  };

  console.log("props.readonly: ", props.readOnly);

  return (
    <>
      <TableContainer component={Paper}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="right"
          alignItems="center"
        >
          {activeProducts ? (
            <Grid item>
              <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{ textTransform: "none" }}
                endIcon={<Add />}
                variant="outlined"
                className="tour-contract-add-product"
                disabled={props.readOnly}
              >
                Select a product to add to contract
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {activeProducts.map((item, key) => {
                  return (
                    <MenuItem
                      onClick={() => handleSelectProduct(item)}
                      key={key}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
          ) : null}
        </Grid>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ fontWeight: "bold" }}>Product Code</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Description</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.products &&
              props.products.map((row, index) => (
                <Row
                  key={row.name}
                  row={row}
                  productIndex={index}
                  handleChange={props.handleChange}
                  handleTriggerChange={props.handleTriggerChange}
                  data={props.products}
                  triggerChange={props.triggerChange}
                  periodicCodes={props.periodicCodes}
                  chargeCodes={props.chargeCodes}
                  product_id={row.id}
                  product_line_id={row.prod_line_id}
                  product_group_id={row.prod_group_id}
                  product_cat_id={row.prod_cat_id}
                  sbu_id={row.sbu_id}
                  loading={loading}
                  setLoading={setLoading}
                  readOnly={props.readOnly}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadingBackdrop open={loading} onClose={() => setLoading(false)} />
    </>
  );
}
